<template>
  <div
    class="payments__choice"
    :class="{
      'shifted' : paymentsDisplay,
      'expanded' : stripeIsVisible
    }"
    @touchstart="touchstart"
    @touchend="touchend"
  >
    <template v-if="step === 1">
      <h4>{{ paymentHeader }}</h4>
      <div class="payments__list" v-show="paypalLoaded || applePayIsVisible">
        <div class="payment payment__apple" v-if="applePayIsVisible">
          <ApplePay ref="applepay" @toggleApplePay="toggleApplePay"/>
        </div>
        <div class="payment payment__paypal" v-else>
          <Paypal ref="paypal" @toggleSpinner="toggleSpinner" @toggleStripe="toggleStripe(false)"/>
        </div>
        <div class="payment payment__separator">or</div>
        <div class="payment payment__visa" @click="toggleStripe(true)" :class="{'selected' : this.stripeIsVisible}">
        </div>
      </div>
      <Stripe ref="stripe" :email="email" v-show="stripeIsVisible" :isVisible="stripeIsVisible"/>  
      <div class="card">
        <div class="card__line card__line_total">  
          <p><span>Total</span><span>$0</span></p>
        </div> 
      </div>
      <Spinner ref="spinner"/>
    </template>
  </div>
</template>

<script>
import Paypal from './Paypal'
import ApplePay from './ApplePay'
import Stripe from './Stripe'
import Spinner from '@/components/Spinner'
export default {
  data() {
    return {
      step: 1,
      email: '',
      paymentsDisplay: false,
      paypalLoaded: false,
      paymentRequest: null,
      applePayIsVisible: true,
      stripeIsVisible: false
    }
  },
  components: {
    Paypal,
    ApplePay,
    Stripe,
    Spinner
  },
  props: {
    terms: String
  },
  computed: {
    paymentHeader() {
      // return `${this.terms} Days for Free! Get it with?`
      return 'Select payment method'
    }
  },
  methods: {
    togglePayments(status) {
      this.paymentsDisplay = status
      this.email = window.email
      // for smooth animation when close
      setTimeout(() => {
        this.toggleStripe(false)  
      }, 300)    
    },    
    touchstart() {
      this.touchstartX = event.changedTouches[0].screenX
      this.touchstartY = event.changedTouches[0].screenY
    },
    touchend() {
      this.touchendX = event.changedTouches[0].screenX
      this.touchendY = event.changedTouches[0].screenY      
      this.hidePayments()
    },
    hidePayments(status = false) {
      // 25 - threshold
      if (this.touchendY > this.touchstartY + 25 || status) {
        if (this.stripeIsVisible) {
          this.toggleStripe(false)
        } else {
          this.togglePayments(false)
          this.$emit('toggleOverflow', false)
          // for smooth animation when close
          setTimeout(() => {
            this.toggleStripe(false)  
            window.scrollTo(0, 0)
          }, 300)  
        }
      }
    },
    toggleSpinner(status) {
      this.paypalLoaded = !status
      this.$refs.spinner.toggleSpinner(status)
    },
    toggleApplePay(status) {
      this.applePayIsVisible = status
      // load Paypal if applePay is not available
      if (!this.applePayIsVisible) {
        this.paypalLoaded = false
        this.toggleSpinner(status)
        this.$nextTick(() => {
          this.$refs.paypal.loadPaypal()
        })
      }
    },
    toggleStripe(status) {
      this.stripeIsVisible = status
    }
  }
}
</script>

<style lang="scss">
@import './styles.scss';
</style>